var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8"},[_c('BaseBox',{staticClass:"max-w-lg w-full grid gap-6"},[_c('BaseBox',[_c('BrandLogo',{staticClass:"mx-auto h-32 w-auto"})],1),_c('BaseBox',{staticClass:"bg-white dark:bg-gray-800 p-14 rounded-lg shadow-lg grid gap-8"},[_c('BaseBox',{attrs:{"tag":"header"}},[_c('BaseHeading',{staticClass:"text-primary dark:text-gray-100 text-2xl font-bold",attrs:{"level":2}},[_c('i18n',{attrs:{"tag":false,"path":"title"}})],1)],1),_c('ValidationObserver',{attrs:{"slim":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{staticClass:"grid gap-8",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('ValidationProvider',{attrs:{"name":_vm.usernameLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseFieldLabel',{attrs:{"label":_vm.usernameLabel,"html-for":"username"}}),_c('BaseInputText',{attrs:{"id":"username","type":"text"},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", $$v)},expression:"model.username"}}),_c('BaseFieldMessage',{attrs:{"id":"username-message","message":errors[0],"tone":"critical"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.passwordLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseFieldLabel',{attrs:{"label":_vm.passwordLabel,"html-for":"password"}}),_c('BaseInputText',{attrs:{"id":"password","autocomplete":"organization","type":"password"},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('BaseFieldMessage',{attrs:{"id":"password-message","message":errors[0],"tone":"critical"}})]}}],null,true)}),_c('BaseBox',{staticClass:"flex space-x-2 items-center"},[_c('input',{staticClass:"h-4 w-4 text-secondary focus:ring-2 focus:ring-secondary-500 border-gray-300 rounded",attrs:{"id":"remember-me","name":"remember-me","type":"checkbox"}}),_c('BaseFieldLabel',{attrs:{"label":_vm.rememberMeLabel,"html-for":"remember-me"}})],1),(_vm.error)?_c('BaseBox',{staticClass:"bg-critical-50 border border-transparent text-critical-900 dark:border-critical-700 dark:bg-critical-700 dark:text-critical-100 dark:border-opacity-40 dark:bg-opacity-40 rounded-lg p-4"},[_c('BaseText',[_c('BaseIcon',{staticClass:"mr-2",attrs:{"name":"exclamation-triangle"}}),_c('i18n',{attrs:{"tag":false,"path":"loginError"}})],1)],1):_vm._e(),_c('BaseBox',{staticClass:"grid"},[_c('BaseButton',{attrs:{"loading":_vm.isSubmitting,"tone":"secondary","type":"submit"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"loginForm.signIn"}})]},proxy:true},{key:"loading-text",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"loginForm.signInLoading"}})]},proxy:true}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }